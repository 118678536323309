import { FEATURE_FLAGS } from "context/FeatureProvider";

export function menuList({ isSuperAdmin = false }) {
  const menuList = [
    { title: "sidebar.dashboard", icon: "side-dashboard", link: "/app/dashboard" },
    { title: "sidebar.orders", icon: "side-orders", link: "/app/orders" },
    { title: "sidebar.events", icon: "side-events", link: "/app/events" },
    { title: "sidebar.affiliates", icon: "side-affiliate", link: "/app/affiliates" },
    { title: "sidebar.discounts", icon: "side-discounts", link: "/app/discounts" },
    { title: "sidebar.questions", icon: "side-questions", link: "/app/questions" },

    { title: "sidebar.videos", icon: "side-videos", link: "/app/videos" },
    { title: "sidebar.sweepstakes", icon: "side-sweepstakes", link: "/app/sweepstakes" },
    {
      title: "sidebar.boomerang",
      icon: "side-boomerang",
      subMenu: [
        { title: "sidebar.boomerang.dashboard", icon: "side-dashboard", link: "/app/boomerang/dashboard" },
        { title: "sidebar.boomerang.allCustomers", icon: "side-guestList", link: "/app/boomerang/all-customers" },
        { title: "sidebar.boomerang.customerList", icon: "side-guestList", link: "/app/boomerang/customer-list" },
        { title: "sidebar.boomerang.campaigns", icon: "side-campaign", link: "/app/boomerang/campaigns" },
        { title: "sidebar.boomerang.emailTemplates", icon: "side-emailTemplates", link: "/app/boomerang/email-templates" },
      ],
    },
    {
      title: "sidebar.guests",
      icon: "side-guests",
      subMenu: [
        { title: "sidebar.boxOffice", icon: "side-boxOffice", link: "/app/guests/box-office" },
        { title: "sidebar.scanTicket", icon: "side-scan", link: "/app/guests/scan-ticket" },
        { title: "sidebar.guestList", icon: "side-guestList", link: "/app/guests/guest-list" },
      ],
    },
    {
      title: "sidebar.settings",
      icon: "side-config",
      subMenu: [
        ...(isSuperAdmin ? [{ title: "containers.Organizations.myOrganizations", icon: "side-organization", link: "/app/settings/organizations" }] : []),
        { title: "sidebar.settings.tenantsettings", icon: "side-appSetting", link: "/app/settings/tenant" },
        { title: "sidebar.venues", icon: "side-venue", link: "/app/venues" },
        { title: "sidebar.fees", icon: "side-fees", link: "/app/fees" },
        { title: "sidebar.associates", icon: "side-associates", link: "/app/associates" },
        { title: "sidebar.settings.paymentprocessors", icon: "side-stripe", link: "/app/settings/paymentprocessors" },
        { title: "sidebar.settings.siteadministrators", icon: "side-admin", link: "/app/settings/siteadministrators" },
        { title: "sidebar.settings.themeSettings", icon: "side-theme", link: "/app/settings/theme-settings" },
        { title: "sidebar.settings.bocaTemplates", icon: "side-template", link: "/app/settings/boca-templates" },
      ],
    },
  ];

  if (FEATURE_FLAGS.allowToAddTAX) {
    menuList.splice(7, 0, { title: "sidebar.taxes", text: "%", link: "/app/taxes" });
  }

  if (FEATURE_FLAGS.isTribeFeatureActive) {
    menuList.splice(3, 0, {
      title: "sidebar.tribe",
      icon: "side-tribe",
      subMenu: [
        { title: "sidebar.tribe.dashboard", icon: "side-dashboard", link: "/app/tribe/dashboard" },
        { title: "sidebar.tribe.registrations", icon: "side-registration", link: "/app/tribe/registrations" },
        { title: "sidebar.tribe.settings", icon: "side-config", link: "/app/tribe/settings" },
      ],
    });
  }

  return menuList;
}
