import React from "react";
import PropTypes from "prop-types";
import Icons from "components/Common/Icons";
import styles from "./BlankSlate.module.scss";
import classNames from "classnames";
import IntlMessages from "util/IntlMessages";

const blankViews = {
  noOrders: {
    iconType: "noOrders",
    title: "blankSlate.noOrders.title",
    info: "blankSlate.noOrders.info",
  },
  noFilteredOrders: {
    iconType: "noOrders",
    title: "blankSlate.noResultsFound.title",
    info: "blankSlate.noResultsFound.noFilteredOrders.info",
  },
  noAffiliates: { iconType: "affiliate", title: "blankSlate.noAffiliates.title", info: "blankSlate.noAffiliates.info" },
  noArchiveAffiliates: { iconType: "noArchive", title: "blankSlate.noArchiveResultsFound.title", info: "blankSlate.noArchiveResultsFound.noArchiveAffiliates.info" },
  noInactiveAffiliates: { iconType: "noInactive", title: "blankSlate.noInactiveResultsFound.title", info: "blankSlate.noInactiveResultsFound.noInactiveAffiliates.info" },
  noSearchAffiliates: {
    iconType: "noSearchFilter",
    title: "blankSlate.noSearchResultsFound.title",
    info: "blankSlate.noSearchResultsFound.noSearchAffiliates.info",
  },
  noTrackingCodes: { iconType: "noTrackingCode", title: "blankSlate.noTrackingCodes.title", info: "blankSlate.noTrackingCodes.info" },
  noArchiveTrackingCodes: { iconType: "noArchive", title: "blankSlate.noArchiveResultsFound.title", info: "blankSlate.noArchiveResultsFound.noArchiveTrackingCodes.info" },
  noInactiveTrackingCodes: { iconType: "noInactive", title: "blankSlate.noInactiveResultsFound.title", info: "blankSlate.noInactiveResultsFound.noInactiveTrackingCodes.info" },
  noDiscounts: { iconType: "discount", title: "blankSlate.noDiscounts.title", info: "blankSlate.noDiscounts.info" },
  noArchiveDiscounts: { iconType: "noArchive", title: "blankSlate.noArchiveResultsFound.title", info: "blankSlate.noArchiveResultsFound.noArchiveDiscounts.info" },
  noInactiveDiscounts: { iconType: "noInactive", title: "blankSlate.noInactiveResultsFound.title", info: "blankSlate.noInactiveResultsFound.noInactiveDiscounts.info" },
  noSearchDiscounts: {
    iconType: "noSearchFilter",
    title: "blankSlate.noSearchResultsFound.title",
    info: "blankSlate.noSearchResultsFound.noSearchDiscounts.info",
  },
  noQuestions: { iconType: "question", title: "blankSlate.noQuestions.title", info: "blankSlate.noQuestions.info" },
  noArchiveQuestions: { iconType: "noArchive", title: "blankSlate.noArchiveResultsFound.title", info: "blankSlate.noArchiveResultsFound.noArchiveQuestions.info" },
  noSearchQuestions: {
    iconType: "noSearchFilter",
    title: "blankSlate.noSearchResultsFound.title",
    info: "blankSlate.noSearchResultsFound.noSearchQuestions.info",
  },
  noVenues: { iconType: "location", title: "blankSlate.noVenues.title", info: "blankSlate.noVenues.info" },
  noArchiveVenues: { iconType: "noArchive", title: "blankSlate.noArchiveResultsFound.title", info: "blankSlate.noArchiveResultsFound.noArchiveVenues.info" },
  noSearchVenues: {
    iconType: "noSearchFilter",
    title: "blankSlate.noSearchResultsFound.title",
    info: "blankSlate.noSearchResultsFound.noSearchVenues.info",
  },
  noFees: { iconType: "noFee", title: "blankSlate.noFees.title", info: "blankSlate.noFees.info" },
  noArchiveFees: { iconType: "noArchive", title: "blankSlate.noArchiveResultsFound.title", info: "blankSlate.noArchiveResultsFound.noArchiveFees.info" },
  noInactiveFees: { iconType: "noInactive", title: "blankSlate.noInactiveResultsFound.title", info: "blankSlate.noInactiveResultsFound.noInactiveFees.info" },
  noSearchFees: {
    iconType: "noSearchFilter",
    title: "blankSlate.noSearchResultsFound.title",
    info: "blankSlate.noSearchResultsFound.noSearchFees.info",
  },
  noTribeRegistrations: { iconType: "noUsers", title: "blankSlate.noTribeRegistrations.title", info: "blankSlate.noTribeRegistrations.info" },
  noSearchTribeRegistrations: {
    iconType: "noSearchFilter",
    title: "blankSlate.noSearchResultsFound.title",
    info: "blankSlate.noSearchResultsFound.noSearchTribeRegistrations.info",
  },
  tribeDisabled: {
    iconType: "settingsDisabled",
    title: "blankSlate.tribeDisabled.title",
    info: "blankSlate.tribeDisabled.info",
  },
  noVideo: {
    iconType: "noVideo",
    title: "blankSlate.noVideo.title",
    info: "blankSlate.noVideo.info",
  },
  noCampaignDetails: {
    iconType: "campaign",
    title: "blankSlate.noCampaignDetails.title",
    info: "blankSlate.noCampaignDetails.info",
  },
  noBoomerangStatistics: {
    iconType: "stats",
    title: "blankSlate.noBoomerangStatistics.title",
    info: "blankSlate.noBoomerangStatistics.info",
  },
  noActivity: {
    iconType: "activityLog",
    title: "blankSlate.noActivity.title",
    info: "blankSlate.noActivity.info",
  },
  noCampaigns: { iconType: "campaign", title: "blankSlate.noCampaigns.title", info: "blankSlate.noCampaigns.info" },
  noCampaignList: { iconType: "campaign", title: "blankSlate.noCampaignList.title", info: "blankSlate.noCampaignList.info" },
  noSearchCampaigns: {
    iconType: "noSearchFilter",
    title: "blankSlate.noSearchResultsFound.title",
    info: "blankSlate.noSearchResultsFound.noSearchCampaigns.info",
  },
  noCustomerList: { iconType: "noUsers", title: "blankSlate.noCustomerList.title", info: "blankSlate.noCustomerList.info" },
  noSearchCustomerList: {
    iconType: "noSearchFilter",
    title: "blankSlate.noSearchResultsFound.title",
    info: "blankSlate.noSearchResultsFound.noSearchCustomerList.info",
  },
  noCustomers: { iconType: "noUsers", title: "blankSlate.noCustomers.title", info: "blankSlate.noCustomers.info" },
  noSearchCustomers: {
    iconType: "noSearchFilter",
    title: "blankSlate.noSearchResultsFound.title",
    info: "blankSlate.noSearchResultsFound.noSearchCustomers.info",
  },
  noSegments: { iconType: "sort", title: "blankSlate.noSegments.title", info: "blankSlate.noSegments.info" },
  noAssignments: { iconType: "noAssignment", title: "blankSlate.noAssignments.title", info: "blankSlate.noAssignments.info" },
  noTribeLeaderboard: { iconType: "noLeaderboard", title: "blankSlate.noTribeLeaderboard.title", info: "blankSlate.noTribeLeaderboard.info" },
  noTribeCodeOrders: { iconType: "noLeaderboard", title: "blankSlate.noTribeCodeOrders.title", info: "blankSlate.noTribeCodeOrders.info" },
  noDiscountCodeOrders: { iconType: "discount", title: "blankSlate.noDiscounts.orders.title", info: "blankSlate.noDiscounts.orders.info" },
  noSaleEvents: { iconType: "mapLocation", title: "blankSlate.noSaleEvents.title", info: "blankSlate.noSaleEvents.info" },
  noEventTickets: { iconType: "ticketLine", title: "blankSlate.noEventTickets.title", info: "blankSlate.noEventTickets.info" },
  noEvents: { iconType: "mapLocation", title: "blankSlate.noEvents.title", info: "blankSlate.noEvents.info" },
  noSearchEvents: {
    iconType: "noSearchFilter",
    title: "blankSlate.noSearchResultsFound.title",
    info: "blankSlate.noSearchResultsFound.noSearchEvents.info",
  },
  noOnSaleEvents: { iconType: "mapLocation", title: "blankSlate.noOnSaleEvents.title", info: "blankSlate.noOnSaleEvents.info" },
  noDraftEvents: { iconType: "mapLocation", title: "blankSlate.noDraftEvents.title", info: "blankSlate.noDraftEvents.info" },
  noPastEvents: { iconType: "mapLocation", title: "blankSlate.noPastEvents.title", info: "blankSlate.noPastEvents.info" },
  noFaqs: { iconType: "faqs", title: "blankSlate.noFaqs.title", info: "blankSlate.noFaqs.info" },
  noEventQuestions: { iconType: "question", title: "blankSlate.noEventQuestions.title", info: "blankSlate.noEventQuestions.info" },
  noDeliveryOptions: { iconType: "delivery", title: "blankSlate.noDeliveryOptions.title", info: "blankSlate.noDeliveryOptions.info" },
  noShippingRates: { iconType: "delivery", title: "blankSlate.noShippingRates.title", info: "blankSlate.noShippingRates.info" },
  noReservedHold: {
    iconType: "reserveHold",
    title: "blankSlate.noReservedHold.title",
    info: "blankSlate.noReservedHold.info",
  },
  noVideoEvent: {
    iconType: "noVideo",
    title: "blankSlate.noVideoEvent.title",
    info: "blankSlate.noVideoEvent.info",
  },
  noTicketTypes: { iconType: "ticketLine", title: "blankSlate.noTicketTypes.title", info: "blankSlate.noTicketTypes.info" },
  noGroupTicketTypes: { iconType: "ticketLine", title: "blankSlate.noGroupTicketTypes.title", info: "blankSlate.noGroupTicketTypes.info" },
  noSearchTicketTypes: {
    iconType: "noSearchFilter",
    title: "blankSlate.noSearchResultsFound.title",
    info: "blankSlate.noSearchResultsFound.noSearchTicketTypes.info",
  },
  noTicketTypeStyle: { iconType: "ticketLine", title: "blankSlate.noTicketTypeStyle.title", info: "" },
  noArchiveTicketTypes: { iconType: "noArchive", title: "blankSlate.noArchiveResultsFound.title", info: "blankSlate.noArchiveResultsFound.noArchiveTicketTypes.info" },
  noFeesTicketType: { iconType: "noFee", title: "blankSlate.noFeesTicketType.title", info: "blankSlate.noFeesTicketType.info" },
  noTicketAddOn: { iconType: "ticketLine", title: "blankSlate.noTicketAddOn.title", info: "blankSlate.noTicketAddOn.info" },
  noAdditionalTicket: { iconType: "ticketLine", title: "blankSlate.noAdditionalTicket.title", info: "blankSlate.noAdditionalTicket.info" },
  noEventTicketQuestions: { iconType: "question", title: "blankSlate.noEventTicketQuestions.title", info: "blankSlate.noEventTicketQuestions.info" },
  configureComboTicket: { iconType: "combo", title: "blankSlate.configureComboTicket.title", info: "blankSlate.configureComboTicket.info" },
  comboTicketDisabled: { iconType: "combo", title: "blankSlate.comboTicketDisabled.title", info: "blankSlate.comboTicketDisabled.info" },
  noAgenda: { iconType: "agenda", title: "blankSlate.noAgenda.title", info: "blankSlate.noAgenda.info" },
  noSales: { iconType: "sell", title: "blankSlate.noSales.title", info: "blankSlate.noSales.info" },
  noEventTicketsReport: { iconType: "stats", title: "blankSlate.noDataAvailable.title", info: "blankSlate.noEventTicketsReport.info" },
  noAffiliateSalesReport: { iconType: "stats", title: "blankSlate.noDataAvailable.title", info: "blankSlate.noAffiliateSalesReport.info" },
  noAffiliateSalesReportFilter: { iconType: "stats", title: "blankSlate.noDataAvailable.title", info: "blankSlate.noAffiliateSalesReportFilter.info" },
  noTransactionsReport: { iconType: "stats", title: "blankSlate.noDataAvailable.title", info: "blankSlate.noTransactionsReport.info" },
  noNetEarningsReport: { iconType: "stats", title: "blankSlate.noDataAvailable.title", info: "blankSlate.noNetEarningsReport.info" },
  noNetEarningsReportFilter: { iconType: "stats", title: "blankSlate.noDataAvailable.title", info: "blankSlate.noNetEarningsReportFilter.info" },
  noOverrides: { iconType: "change", title: "blankSlate.noOverrides.title", info: "blankSlate.noOverrides.info" },
  noTimeSlots: { iconType: "timer", title: "blankSlate.noTimeSlots.title", info: "blankSlate.noTimeSlots.info" },
  eventNotSchedule: { iconType: "schedule", title: "blankSlate.eventNotSchedule.title", info: "blankSlate.eventNotSchedule.info" },
  noSeatingInfo: { iconType: "seatingHall", title: "blankSlate.noSeatingInfo.title", info: "blankSlate.noSeatingInfo.info" },
  noSeatingInfoRow: { iconType: "seatingRow", title: "blankSlate.noSeatingInfoRow.title", info: "blankSlate.noSeatingInfoRow.info" },
  noSeatingInfoSeat: { iconType: "seatingSeat", title: "blankSlate.noSeatingInfoSeat.title", info: "blankSlate.noSeatingInfoSeat.info" },
  noOrganizations: { iconType: "businessBag", title: "blankSlate.noOrganizations.title", info: "blankSlate.noOrganizations.info" },
  noSite: { iconType: "www", title: "blankSlate.noSite.title", info: "blankSlate.noSite.info" },
  noAdmin: { iconType: "admin", title: "blankSlate.noAdmin.title", info: "blankSlate.noAdmin.info" },
  noPaymentProcessors: { iconType: "wallet", title: "blankSlate.noPaymentProcessors.title", info: "blankSlate.noPaymentProcessors.info" },
  noArchivePaymentProcessors: { iconType: "noArchive", title: "blankSlate.noArchiveResultsFound.title", info: "blankSlate.noArchiveResultsFound.noArchivePaymentProcessors.info" },
  noInactivePaymentProcessors: { iconType: "noInactive", title: "blankSlate.noInactiveResultsFound.title", info: "blankSlate.noInactiveResultsFound.noInactivePaymentProcessors.info" },
  noTransactionFees: { iconType: "noFee", title: "blankSlate.noTransactionFees.title", info: "blankSlate.noTransactionFees.info" },
  noSiteAdmin: { iconType: "admin", title: "blankSlate.noSiteAdmin.title", info: "blankSlate.noSiteAdmin.info" },
  noBocaTemplate: { iconType: "bocaTemplate", title: "blankSlate.noBocaTemplate.title", info: "blankSlate.noBocaTemplate.info" },
  noBocaCommand: { iconType: "bocaTemplate", title: "blankSlate.noBocaCommand.title", info: "blankSlate.noBocaCommand.info" },
  noSiteBilling: { iconType: "bill", title: "blankSlate.noSiteBilling.title", info: "blankSlate.noSiteBilling.info" },
  noGuestList: { iconType: "noUsers", title: "blankSlate.noGuestList.title", info: "blankSlate.noGuestList.info" },
  noGuestListFilter: { iconType: "noSearchFilter", title: "blankSlate.noGuestListFilter.title", info: "blankSlate.noGuestListFilter.info" },
  noEventDays: { iconType: "calender", title: "blankSlate.noEventDays.title", info: "blankSlate.noEventDays.info" },
  noDiscountDetails: { iconType: "discount", title: "blankSlate.noDiscountDetails.title", info: "blankSlate.noDiscountDetails.info" },
  noBuyerAnswer: { iconType: "question", title: "blankSlate.noBuyerAnswer.title", info: "blankSlate.noBuyerAnswer.info" },
  noBuyerAnswerError: { iconType: "question", title: "blankSlate.noBuyerAnswerError.title", info: "blankSlate.noBuyerAnswerError.info" },
  noTransactionDetails: { iconType: "bill", title: "blankSlate.noTransactionDetails.title", info: "blankSlate.noTransactionDetails.info" },
  noOrderTicketType: { iconType: "ticketLine", title: "blankSlate.noOrderTicketType.title", info: "blankSlate.noOrderTicketType.info" },
  noRecurringOrdersExist: { iconType: "noOrders", title: "blankSlate.noRecurringOrdersExist.title", info: "blankSlate.noRecurringOrdersExist.info" },
  noRecurringTicketsExist: { iconType: "ticketLine", title: "blankSlate.noRecurringTicketsExist.title", info: "blankSlate.noRecurringTicketsExist.info" },
  noTenantSite: { iconType: "www", title: "blankSlate.noTenantSite.title", info: "blankSlate.noTenantSite.info" },
  noTenantSiteSearch: { iconType: "noSearchFilter", title: "blankSlate.noSearchResultsFound.title", info: "blankSlate.noTenantSiteSearch.info" },
  noMemberReward: { iconType: "discount", title: "blankSlate.noMemberReward.title", info: "blankSlate.noMemberReward.info" },
  noEventSchedule: { iconType: "schedule", title: "blankSlate.noEventSchedule.title", info: "blankSlate.noEventSchedule.info" },
  noEventTimeSlot: { iconType: "schedule", title: "blankSlate.noEventTimeSlot.title", info: "blankSlate.noEventTimeSlot.info" },
  noVideoLocator: { iconType: "noVideo", title: "blankSlate.noVideoLocator.title", info: "blankSlate.noVideoLocator.info" },
  noAssociate: { iconType: "side-associates", title: "blankSlate.noAssociate.title", info: "blankSlate.noAssociate.info" },
  noSearchAssociate: {
    iconType: "noSearchFilter",
    title: "blankSlate.noSearchResultsFound.noAssociate.title",
    info: "blankSlate.noSearchResultsFound.noAssociate.info",
  },
  noSearchEventAssociateGroups: {
    iconType: "noSearchFilter",
    title: "blankSlate.noSearchEventAssociateGroups.title",
    info: "blankSlate.noSearchEventAssociateGroups.info",
  },
  noEventAssociateGroups: {
    iconType: "side-associates",
    title: "blankSlate.noEventAssociates.title",
    info: "blankSlate.noEventAssociates.info",
  },
  noTicketTypeFees: { iconType: "noFee", title: "blankSlate.noTicketTypeFees.title", info: "blankSlate.noTicketTypeFees.info" },
  noRecurringEventDays: { iconType: "schedule", title: "blankSlate.noRecurringEventDays.title", info: "blankSlate.noRecurringEventDays.info" },
  noAbandonedCarts: { iconType: "noOrders", title: "containers.reports.abandonedCarts.orders.title", info: "containers.reports.abandonedCarts.orders.info" },
};

function BlankSlate(props) {
  const { view, className, children } = props;

  return (
    <div className={classNames(styles.container, { [className]: !!className })}>
      <div className={styles.iconWrapper}>
        <Icons type={blankViews[view].iconType} className={styles.icon} size="7xl" />
      </div>
      <div className={styles.details}>
        <div className={classNames(styles.title, "typo-18-600 md:typo-20-600")}>
          <IntlMessages id={blankViews[view].title} />
        </div>
        {blankViews[view].info && (
          <div className={styles.info}>
            <IntlMessages id={blankViews[view].info} />
          </div>
        )}
        {children && <div className="px-3 py-2 mt-3">{children}</div>}
      </div>
    </div>
  );
}

export default BlankSlate;

BlankSlate.propTypes = {
  view: PropTypes.oneOf([
    "noOrders",
    "noFilteredOrders",
    "noAffiliates",
    "noArchiveAffiliates",
    "noInactiveAffiliates",
    "noSearchAffiliates",
    "noTrackingCodes",
    "noArchiveTrackingCodes",
    "noInactiveTrackingCodes",
    "noDiscounts",
    "noArchiveDiscounts",
    "noInactiveDiscounts",
    "noSearchDiscounts",
    "noQuestions",
    "noArchiveQuestions",
    "noSearchQuestions",
    "noVenues",
    "noArchiveVenues",
    "noSearchVenues",
    "noFees",
    "noArchiveFees",
    "noInactiveFees",
    "noSearchFees",
    "noTribeRegistrations",
    "noSearchTribeRegistrations",
    "noTribeRegistrations",
    "noSearchTribeRegistrations",
    "tribeDisabled",
    "noVideo",
    "noCampaignDetails",
    "noBoomerangStatistics",
    "noActivity",
    "noCampaigns",
    "noCampaignList",
    "noCampaignList",
    "noSearchCampaigns",
    "noCustomerList",
    "noSearchCustomerList",
    "noCustomers",
    "noSearchCustomers",
    "noSegments",
    "noAssignments",
    "noTribeLeaderboard",
    "noTribeCodeOrders",
    "noDiscountCodeOrders",
    "noSaleEvents",
    "noEventTickets",
    "noEvents",
    "noSearchEvents",
    "noOnSaleEvents",
    "noDraftEvents",
    "noPastEvents",
    "noFaqs",
    "noEventQuestions",
    "noDeliveryOptions",
    "noShippingRates",
    "noReservedHold",
    "noVideoEvent",
    "noTicketTypes",
    "noSearchTicketTypes",
    "noArchiveTicketTypes",
    "noFeesTicketType",
    "noTicketAddOn",
    "noAdditionalTicket",
    "noEventTicketQuestions",
    "configureComboTicket",
    "comboTicketDisabled",
    "noAgenda",
    "noSales",
    "noEventTicketsReport",
    "noAffiliateSalesReport",
    "noAffiliateSalesReportFilter",
    "noTransactionsReport",
    "noNetEarningsReport",
    "noNetEarningsReportFilter",
    "noOverrides",
    "noTimeSlots",
    "eventNotSchedule",
    "noSeatingInfo",
    "noSeatingInfoRow",
    "noSeatingInfoSeat",
    "noOrganizations",
    "noSite",
    "noAdmin",
    "noPaymentProcessors",
    "noArchivePaymentProcessors",
    "noInactivePaymentProcessors",
    "noTransactionFees",
    "noSiteAdmin",
    "noBocaTemplate",
    "noBocaCommand",
    "noSiteBilling",
    "noGuestList",
    "noGuestListFilter",
    "noEventDays",
    "noDiscountDetails",
    "noBuyerAnswer",
    "noBuyerAnswerError",
    "noTransactionDetails",
    "noOrderTicketType",
    "noRecurringOrdersExist",
    "noRecurringTicketsExist",
    "noTenantSite",
    "noTenantSiteSearch",
    "noMemberReward",
    "noEventSchedule",
    "noEventTimeSlot",
    "noVideoLocator",
    "noTicketTypeStyle",
    "noGroupTicketTypes",
    "noAssociate",
    "noSearchAssociate",
    "noTicketTypeFees",
    "noRecurringEventDays",
    "noAbandonedCarts",
  ]).isRequired,
  className: PropTypes.string,
};

BlankSlate.defaultProps = {
  className: "",
};
