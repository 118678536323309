const clientAADName = process.env.REACT_APP_AAD_NAME || "helium7dev";
const eventAPIBaseURL = process.env.REACT_APP_EVENTS_API_BASE_URL || "https://eventsapi-dev.ticketblox.com/";
const storeAPIBaseURL = process.env.REACT_APP_STORE_API_BASE_URL || "https://storefrontapi-dev.ticketblox.com/";
const mediaAPIBaseURL = process.env.REACT_APP_MEDIA_API_BASE_URL || "https://videoapi-dev.ticketblox.com/";
const orderAPIBaseURL = process.env.REACT_APP_ORDERS_API_URL || "https://ordersapi-dev.ticketblox.com/";
const scanningAPIBaseURL = process.env.REACT_APP_SCANNING_API_URL || "https://scanningapi-dev.ticketblox.com/";
const boomerangAPIBaseURL = process.env.REACT_APP_BOOMERANG_API_URL || "https://boomerangapi-dev.ticketblox.com/";

export default {
  eventsAPI: eventAPIBaseURL,
  baseUrl: process.env.REACT_APP_BASE_URL,
  eventsBaseUrl: `${eventAPIBaseURL}api/tenants/`,
  eventsApiUrl: `${eventAPIBaseURL}api/tenants/`,
  storeFrontApiUrl: storeAPIBaseURL,
  mediaApiUrl: mediaAPIBaseURL,
  scanningApiUrl: scanningAPIBaseURL,
  catalogApiUrl: `${eventAPIBaseURL}api/`,
  hotelsApiUrl: `${eventAPIBaseURL}api/`,
  ordersApiUrl: `${orderAPIBaseURL}`,
  boomerangApiUrl: `${boomerangAPIBaseURL}tenants/`,
  idSrvUrl: `https://${clientAADName}.b2clogin.com/${clientAADName}.onmicrosoft.com/b2c_1a_new_signin/openid/v2.0/`,
  venueSeatSvgUrl: process.env.REACT_APP_VENUE_SEAT_SVG_URL || "https://tbprodstorage.blob.core.windows.net/venues",
  clientId: process.env.REACT_APP_CLIENT_ID,
  environment: process.env.REACT_APP_ENVIRONMENT || "development",
  mapAccessToken: process.env.REACT_APP_MAB_BOX_ACCESS_TOKEN,
  htBlobUrl: process.env.REACT_APP_HT_BLOB_URL,
  changePassword: `https://${clientAADName}.b2clogin.com/${clientAADName}.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_PROFILE_EDIT_PASSWORD_CHANGE&client_id=${process.env.REACT_APP_AAD_CLIENT_ID}&nonce=defaultNonce&redirect_uri=${process.env.REACT_APP_WEB_ADMIN_URL}oidc-response&scope=openid&response_type=id_token&prompt=login`,
};
