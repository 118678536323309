import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAuthContext } from "./AuthProvider";
import { useRouter } from "./RouteContext";
import { useSeedData } from "hooks/useSeedData";
import { getLocaleFromStorage, setLocaleToStorage } from "util/localeHelper";
import { languageData } from "util/localeHelper";

export const SeedContext = createContext({});

export const useSeedContext = () => useContext(SeedContext);

function SeedProvider(props) {
  const router = useRouter();
  const { userId, isAuthenticated } = useAuthContext();
  const tenantId = localStorage.getItem("tenant_id");
  const [locale, setLocale] = useState(languageData[0]);
  const [navCollapsed, setNavCollapsed] = useState(false);

  useEffect(() => {
    const currentLocaleId = getLocaleFromStorage();
    if (currentLocaleId) {
      const findLocale = languageData.find((lItem) => lItem.languageId === currentLocaleId);
      setLocale(findLocale);
    }
  }, []);

  const {
    commandTypes,
    rotateTypes,
    fontTypes,
    dataFields,
    userProfile,
    userInfo,
    qzCertificate,
    associateTypes,
    timezonesData,
    isTimezonesDataLoading,
    isTimezonesDataSuccess,
    currenciesData,
    isCurrenciesDataLoading,
    isCurrenciesDataSuccess,
    localesData,
    isLocalesDataLoading,
    isLocalesDataSuccess,
  } = useSeedData(tenantId, userId, isAuthenticated);

  const toggleCollapsedNav = () => {
    setNavCollapsed((v) => !v);
  };

  const switchLanguage = (v) => {
    setLocale(v);
    setLocaleToStorage(v.languageId);
  };

  const value = useMemo(
    () => ({
      locale,
      locales: localesData?.items || [],
      // drawerType: "fixed_drawer",
      // navigationStyle: "vertical_navigation",
      // horizontalNavPosition: "inside_the_header",
      navCollapsed,
      currencies: currenciesData?.items || [],
      timezones: timezonesData?.items || [],
      commandTypes: commandTypes,
      rotateTypes: rotateTypes,
      fontTypes: fontTypes,
      dataFields: dataFields,
      qzCertificate: qzCertificate,
      associateTypes: associateTypes || [],
      toggleCollapsedNav: toggleCollapsedNav,
      switchLanguage: switchLanguage,
      userProfile: userProfile,
      userInfo: userInfo,
    }),
    [
      locale,
      navCollapsed,
      commandTypes,
      rotateTypes,
      fontTypes,
      dataFields,
      qzCertificate,
      associateTypes,
      router,
      timezonesData,
      isTimezonesDataLoading,
      isTimezonesDataSuccess,
      currenciesData,
      isCurrenciesDataLoading,
      isCurrenciesDataSuccess,
      localesData,
      isLocalesDataLoading,
      isLocalesDataSuccess,
      userProfile,
      userInfo,
    ]
  );

  return <SeedContext.Provider value={value}>{props.children}</SeedContext.Provider>;
}

export default SeedProvider;
